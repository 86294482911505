<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Event</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <!-- <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" /> -->
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="templateEventList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Events</h5>
                        </template>
                        <!-- <template v-slot:right>
                            <Button label="Add Template Event" icon="pi pi-plus" class="p-mr-2"
                                @click="addTemplateEventRedirect(data)" />
                        </template> -->
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header=" Event Name" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.te3 ? data.te3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Channel Name" headerStyle="width: 35%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-for="(value, index) in data.te4.split(',')" :key="index">
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 1"> <span>
                                            WhatsApp</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 4"> <span>
                                            Email</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 2"> <span>
                                            RCS</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 3"> <span>
                                            SMS</span>
                                    </Chip>
                                    <Chip class="p-mr-1 p-mb-1 custom-multi-chip-hosts" v-if="value == 5"> <span>
                                            Push Notifications</span>
                                    </Chip>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column field="name" header="Communicating To" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ getCommunicatingToLabel(data.ta52) }}</div>
                        </template>
                    </Column>
                    <!-- <Column header="Status" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-if="data.te5 == 1"> Active</span>
                                <span v-if="data.te5 == 0"> Inactive</span>
                            </div>
                        </template>
                    </Column> -->
                    <Column header="Action" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editTemplateEventDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>

</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';

import { required, helpers } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            templateEventList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            localActionId: '',
            localClientId: '',
            localClientName: '',
            actionTakingList: [{ value: 1, label: 'Action Taking User' },
            { value: 2, label: 'Admin User' },
            { value: 3, label: 'Support User' },
            { value: 4, label: 'Voter' },
            { value: 5, label: 'Current User' }
            ],
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.localActionId = localStorage.localActionId;
        this.localClientId = localStorage.localClientId;
        this.localClientName = localStorage.localClientName;
        this.getTemplateActionsOnly();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        this.imagestorageimgpath = this.storage_path;
        this.getTemplateEvents();
    },
    validations() {
        return {
            add: {
                ChannelName: { required: helpers.withMessage('Please select tune type', required) },
                templateAction: { required: helpers.withMessage('Please select tune catagory', required) },
                templateName: { required: helpers.withMessage('Please enter tune alias name', required) },

            },
            edit: {
                ChannelName: { required: helpers.withMessage('Please select tune type', required) },
                templateAction: { required: helpers.withMessage('Please select tune catagory', required) },
                templateName: { required: helpers.withMessage('Please enter tune alias name', required) },

            },
        };
    },
    methods: {
        getCommunicatingToLabel(e) {
            if (e != null && e != 0) {
                let vals = this.actionTakingList.filter(function (item) {
                    return item.value == e;
                });
                if (vals.length > 0) {
                    return vals[0].label;
                } else {
                    return "-"
                }
            } else {
                return "-"
            }
        },
        addTemplateEventRedirect() {
            router.push({
                name: "addEditEventTemplate",
                params: { eventId: 0 },
            });
        },

        getTemplateEvents() {
            this.loading = true;
            this.ApiService.getTemplateEvents({ actionId: this.localActionId, clientId: this.localClientId, showActionId: 1 }).then((data) => {
                if (data.status == 200) {
                    //this.templateEventList = data.result;
                    this.templateEventList = data.result;
                    // var templateEventList = data.result;
                    // let arrConverted = templateEventList;
                    // for (let i = 0; i < arrConverted.length; i++) {
                    //     let vals = data.actionTaking.filter(function (item) {
                    //         return item.ta3 == data.result[i]['te1'];
                    //     });
                    //     if (vals.length > 0) {
                    //         let newElement = vals[0]['ta52'];
                    //         if (templateEventList[i]) {
                    //             this.templateEventList.push({
                    //                 'te1': templateEventList[i]['te1'],
                    //                 'te2': templateEventList[i]['te2'],
                    //                 'te3': templateEventList[i]['te3'],
                    //                 'te4': templateEventList[i]['te4'],
                    //                 'te5': templateEventList[i]['te5'],
                    //                 'ta52': newElement
                    //             });
                    //         }
                    //     } else {
                    //         if (templateEventList[i]) {
                    //             this.templateEventList.push({
                    //                 'te1': templateEventList[i]['te1'],
                    //                 'te2': templateEventList[i]['te2'],
                    //                 'te3': templateEventList[i]['te3'],
                    //                 'te4': templateEventList[i]['te4'],
                    //                 'te5': templateEventList[i]['te5'],
                    //                 'ta52': '0'
                    //             });
                    //         }
                    //     }
                    // }
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.templateEventList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        getTemplateActionsOnly(ev) {
            this.loading = true;
            this.ApiService.getTemplateActionsOnly(ev).then((data) => {
                if (data.status == 200) {
                    this.templateActionList = data.data;
                    this.loading = false;
                } else {
                    this.templateActionList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getTemplateEvents({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },

        editTemplateEventDialogOpen(e) {
            router.push({
                name: "addEditEventTemplate",
                params: { eventId: e.te1 },
            });
        },

        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.localClientId },

            });
        },
        goToPrevious() {
            router.push({
                name: "template-actions",
                params: { clientSubId: this.localClientId },

            });
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}

.custom-multi-chip-hosts.p-chip {
    background: rgb(200, 213, 230);
    color: rgb(37, 61, 96);
    padding: 0.3rem 0.8rem;
}

.custom-multi-chip-hosts.p-chip .p-chip-text {
    line-height: 0.5;
    font-size: 13px;
}
</style>
